<template>
  <div class="careers" :class="{ mobile: isPhone }">
    <Hero heading="Working at Electrify America" :subheading="false">
      <template slot="image">
        <img v-if="isPhone" src="@/assets/images/Careers/hero_mobile.png" alt="A woman writing on a whiteboard." />
        <img v-else src="@/assets/images/Careers/hero.png" alt="A woman writing on a whiteboard." />
      </template>
    </Hero>
    <section class="hero-body">
      <div class="container">
        <div class="flex-column">
          <div class="content">
            <h2>Help Us Build the Future of Mobility</h2>
            <p>
              We’re growing the nation’s largest public electric vehicle (EV) Ultra-Fast charging network. We’re always
              looking for smart, hardworking and passionate people to join our efforts to bring freedom to EV drivers
              across the country.
            </p>
            <p>
              <a
                class="button"
                href="https://vwgoa.taleo.net/careersection/ea_careers/jobsearch.ftl?lang=en"
                rel="noopener"
                target="_blank"
                >Browse Jobs</a
              >
            </p>
          </div>
          <img class="sub-image" :src="require('@/assets/images/Careers/circled-employee.png')" alt="" />
        </div>
      </div>
    </section>
    <section class="culture">
      <div class="container flex-column">
        <h2>Our Company</h2>
        <p class="description">
          At Electrify America, we believe in inclusivity and diversity. Our “room for all” mindset is central to our
          core beliefs, and a part of our larger mission. You’ll see this reflected across our people, programs, and
          products.
        </p>
        <div class="flex">
          <div class="header">
            <div class="icon"><LightningBoltIcon /></div>
            <h3>Our people</h3>
          </div>
          <div class="content flex-column">
            <p>
              Electrify America team members are trailblazers. They are motivated and committed to achieving their
              goals. They are compassionate and supportive of one another. And they are encouraged to bring their
              authentic and unique selves to work every day.
            </p>
            <p>
              Our employee-driven Diversity and Inclusion Committee serves as a resource to continually empower
              Electrify America team members to drive change. The committee supports talent acquisition and corporate
              social responsibility activities, and ensures best practices are considered in all marketing and
              advertising content.
            </p>
          </div>
        </div>
        <div class="flex">
          <div class="header">
            <div class="icon"><LightningBoltIcon /></div>
            <h3>Our programs</h3>
          </div>
          <div class="content flex-column">
            <p>
              We believe electric vehicles should be accessible to all. Electrify America’s commitment to expanding zero
              emission vehicle (ZEV) education and awareness has a clear focus on low-income and disadvantaged
              communities. We support programs dedicated to expanding access to affordable and sustainable
              transportation options in these communities.
            </p>
          </div>
        </div>
        <div class="flex">
          <div class="header">
            <div class="icon"><LightningBoltIcon /></div>
            <h3>Our products</h3>
          </div>
          <div class="content flex-column">
            <p>
              Electrify America’s public, commercial charging solutions are enabling the future of electric mobility,
              for all types of EV drivers. Our public network offers both CCS and CHAdeMO connectors, with Level 2
              chargers at select locations as well. Overall, we’re focused on identifying – and solving – the needs of
              EV drivers everywhere, and our charging stations are compatible with almost every EV model on the road.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="benefits">
      <div class="container">
        <div class="content">
          <h2>Benefits</h2>
          <p>
            We offer a comprehensive benefits package designed to help our team members thrive and grow. Some of the
            many benefits we provide to our full-time employees include:
          </p>
        </div>
        <div class="flex">
          <div class="content flex-column">
            <img
              loading="lazy"
              src="@/assets/images/Careers/benefits-lifelong-learning.svg?external"
              alt=""
              class="icon"
            />
            <h3>Lifelong Learning</h3>
            <p>
              We value education at every stage of life – which is why we offer contributions toward student loan
              repayments and undergraduate and graduate tuition expenses for eligible employees. We also provide and
              encourage continual training for a variety of business and soft skills, both onsite and virtually.
            </p>
          </div>
          <div class="content flex-column">
            <img
              loading="lazy"
              src="@/assets/images/Careers/benefits-flexible-work-arrangements.svg?external"
              alt=""
              class="icon"
            />
            <h3>Flexible Work Arrangements</h3>
            <p>
              We work with our employees on an individual basis to define how, when, and where work is performed. While
              this varies based on department and role, we believe in supporting the needs of all employees so they can
              perform to the best of their abilities.
            </p>
          </div>
          <div class="content flex-column">
            <img
              loading="lazy"
              src="@/assets/images/Careers/benefits-medical-insurance.svg?external"
              alt=""
              class="icon"
            />
            <h3>Medical Insurance</h3>
            <p>
              We support our employees’ physical and mental wellbeing with insurance coverage options for medical,
              prescription drug, dental, and vision expenses, and the option to enroll in a tax-free Health Savings
              Account.
            </p>
          </div>
          <div class="content flex-column">
            <img
              loading="lazy"
              src="@/assets/images/Careers/benefits-paid-parental-leave.svg?external"
              alt=""
              class="icon"
            />
            <h3>Paid Parental Leave</h3>
            <p>
              Our paid maternity and paid non-primary caregiver leave for eligible employees allows new parents –
              whether birth or adoptive – the time to bond with and care for their new child.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Card headlineLevel="h2" heading="Feeling charged up?" headlineDisplayClass="headline3">
      <template #image>
        <picture>
          <img
            loading="lazy"
            style="border-radius: 8px; max-width: 460px"
            src="@/assets/images/Careers/EADesertHills20591.png"
            alt="A man working on a laptop"
          />
        </picture>
      </template>
      <div>
        <p>Check out our current job opportunities.</p>
        <p>Electrify America is an equal opportunity employer.</p>
        <p>
          <a
            class="button"
            href="https://vwgoa.taleo.net/careersection/ea_careers/jobsearch.ftl?lang=en"
            rel="noopener"
            target="_blank"
            >Browse Jobs</a
          >
        </p>
      </div>
    </Card>
  </div>
</template>

<script>
// eslint-disable-next-line
import LightningBoltIcon from '@/assets/images/Careers/vector_23.svg';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'careers',
  metaInfo: {
    title: 'View our career opportunities | Electrify America',
    meta: [
      {
        name: 'description',
        content: `It’s an exciting time to work in the electric vehicle (EV) industry. If you want to 'Electrify America' with us, check out our job listings.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/careers/' }],
  },
  components: {
    LightningBoltIcon,
    Card,
    Hero,
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isTiny() {
      return this.$resize && this.$mq.below(430);
    },
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.careers {
  //  Default Page Styling

  h2 {
    margin-bottom: 16px;

    @media (min-width: 750px) {
      font-size: 32px;
    }
  }

  h3 {
    font-weight: $fw-medium;
    font-size: 21px;
    line-height: 1;
    margin-bottom: 1em;
  }

  .button {
    padding: 1.28em 1.6em;
  }

  // Utility Classes
  .container {
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .arrow {
    margin-left: 8px;
    path {
      fill: $c-primary;
    }
  }

  // Section Grouping

  .culture,
  .benefits {
    padding: 60px 0;
    @media (min-width: 1130px) {
      padding: 100px 0;
    }
  }

  .hero-body,
  .benefits {
    background-color: $c-secondary-background;
  }

  .culture,
  .benefits {
    .container {
      max-width: 1000px;
    }
  }

  // Sections

  .hero-body {
    margin-bottom: 100px;
    padding-bottom: 0;

    img {
      border-radius: 8px;
      margin-top: -300px;
    }
    .sub-image {
      border-radius: 0;
      margin: 0 0 -80px 0;
      flex: 0 0 100%;
      max-width: 365px;

      @media (min-width: 750px) {
        margin-left: 80px;
      }
      @media (min-width: 1260px) {
        margin-left: 120px;
        margin-right: 0;
      }
    }
    .flex-column {
      padding-top: 40px;
      @media (min-width: 750px) {
        padding-top: 100px;
        flex-direction: row;
      }
      @media (min-width: 1000px) {
        margin: 0 auto;
        max-width: 1000px;
      }
      @media (min-width: 1260px) {
        margin: 0 0 0 auto;
        max-width: 1100px;
      }

      h3,
      p {
        max-width: 800px;
      }

      h2 {
        margin-bottom: 16px;
      }
    }
  }

  .culture {
    padding-bottom: 60px;
    .description {
      max-width: 60%;
    }

    h3 {
      max-width: 400px;
      margin-bottom: 16px;
    }
    .flex {
      display: block;
      flex-direction: column;
      margin-top: 2em;
      margin-bottom: 2em;

      .content {
        display: block;
        margin-left: 34px;

        p {
          margin: 0;
          + p {
            margin-top: 2em;
          }
        }
        @media (min-width: 750px) {
          border-left: 1px solid #011352;
          padding-left: 119px;
        }
      }
      @media (min-width: 750px) {
        display: flex;
        flex-direction: row;
      }
    }

    .header {
      flex: 0;
      display: flex;

      .icon {
        flex: 0 0 18px;
        margin-right: 16px;

        svg {
          height: 32px;
        }
      }
      @media (min-width: 750px) {
        flex: 0 0 240px;
      }
    }

    @media (min-width: 750px) {
      padding-top: 0;
    }
  }

  .benefits {
    background-image: url('~@/assets/images/Careers/union.png');
    background-position: right top;
    background-size: auto 100px;
    background-repeat: no-repeat;
    padding-bottom: 40px;

    .icon {
      width: 56px;
      height: 56px;
      margin-bottom: 16px;
    }
    .flex {
      display: block;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;

      .content {
        @media (min-width: 750px) {
          flex-basis: calc(50% - 60px);
          margin: 36px 0 0 60px;
        }
      }

      @media (min-width: 750px) {
        display: flex;
        flex-direction: row;
        margin: 0 0 0 -60px;
      }
    }
    .flex-column {
      margin-top: 40px;
    }
    .content {
      flex: 1;
    }

    h3 {
      margin-bottom: 21px;
    }

    p {
      margin-bottom: 24px;
    }
    .container > .content {
      max-width: 580px;
    }
    @media (min-width: 750px) {
      background-size: 30% auto;
    }
    @media (min-width: 1000px) {
      background-size: 40% auto;
    }
  }
}
</style>
